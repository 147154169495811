
import clsx from 'clsx'
import styles from './styles.module.css'

const ArrowAnimation = ({isWhite = false, classes = ''}) => {
  return(
    <svg className={clsx(styles.drawArrow, classes)} width="138" height="81" viewBox="0 0 138 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector 12" d="M3 78.0003C24.3689 71.3566 60 58 67 46.5C74 35 65.7998 29.0198 58.2678 27.0922C54 26 47.5722 27.8723 43.0826 32.2366C34.6385 40.4452 31.8999 57.4484 47.3752 62.0848C64.2223 67.1322 79.7736 60.7288 97.5851 40.5963C115.397 20.4638 121.718 11.3579 128.909 6.99799C133.682 4.10377 129.023 5.06886 125.475 5.06886C120.428 5.06886 118.417 5.33075 119.948 5.06886C124.422 4.30345 129.45 4.35547 133.523 3.19332C137.878 1.95083 131.279 7.06735 130.626 8.01616C128.567 11.0074 126.226 15.8442 126.226 19.5375" stroke={isWhite ? "#fff" : "#000"} strokeWidth="5" strokeLinecap="round" />
    </svg>
  )
}

export default ArrowAnimation