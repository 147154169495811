import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import LinkComp from 'components/atoms/LinkComp';
import { CART_URL } from 'constants/Paths';
import styles from './styles.module.css';

interface props {
  totalQuantity: number;
}

const CartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M16.3943 2.4H3.74199L2.66095 0.369213C2.53961 0.141267 2.31006 0 2.061 0H0.687001C0.307581 0 0 0.322355 0 0.72C0 1.11765 0.307581 1.44 0.687001 1.44H1.65759L2.72446 3.44415L5.1849 9.14964L5.18742 9.15547L5.40414 9.658L2.93383 12.4196C2.7562 12.6181 2.7006 12.9047 2.79013 13.1602C2.87965 13.4157 3.09899 13.5964 3.35701 13.6273L5.60867 13.897C8.42507 14.2344 11.269 14.2344 14.0854 13.897L16.337 13.6273C16.714 13.5822 16.9847 13.2253 16.9416 12.8302C16.8985 12.4352 16.558 12.1515 16.181 12.1967L13.9294 12.4664C11.2166 12.7913 8.47741 12.7913 5.76468 12.4664L4.8711 12.3593L6.68422 10.3324C6.70175 10.3128 6.71804 10.2925 6.73308 10.2715L7.42266 10.3655C8.38897 10.4973 9.36576 10.5231 10.3372 10.4426C12.6029 10.2548 14.7027 9.12488 16.1688 7.30471L16.6983 6.64728C16.7161 6.6252 16.7326 6.60202 16.7477 6.57787L17.7346 5.00169C18.4341 3.88447 17.6694 2.4 16.3943 2.4Z"
      fill="white"
    />
    <path
      d="M4.80901 15.12C4.05017 15.12 3.435 15.7647 3.435 16.56C3.435 17.3553 4.05017 18 4.80901 18C5.56785 18 6.18301 17.3553 6.18301 16.56C6.18301 15.7647 5.56785 15.12 4.80901 15.12Z"
      fill="white"
    />
    <path
      d="M13.511 16.56C13.511 15.7647 14.1262 15.12 14.885 15.12C15.6439 15.12 16.259 15.7647 16.259 16.56C16.259 17.3553 15.6439 18 14.885 18C14.1262 18 13.511 17.3553 13.511 16.56Z"
      fill="white"
    />
  </svg>
);

export default function NotificationV2({ totalQuantity }: props) {
  return (
    <Box className={styles.headerStatus}>
      <div id="cart__icon" className={styles.cartContainer}>
        <LinkComp href={CART_URL} className={styles.cartLink} name={undefined} onMouseOver={undefined} target={undefined} onClick={undefined}>
          <Tooltip title="Giỏ hàng" arrow>
            <div className={styles.cart} data-test="cart-link">
              <div className={styles?.badgeContainer}>
                <CartIcon />
                {totalQuantity > 0 ? (
                  <span className={styles?.badge}>{totalQuantity >= 100 ? '99+' : totalQuantity}</span>
                ) : (
                  <span className={styles?.badge}>0</span>
                )}
              </div>
              Giỏ Hàng
            </div>
          </Tooltip>
        </LinkComp>
      </div>
    </Box>
  );
}

