import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import AlertSection from 'components-v2/atoms/AlertSection';
import Header from 'components-v2/layout/Header';
import { ButtonHeader } from 'components/atoms/Button';
import HeadPage from 'components/atoms/HeadPage';
import { EventHeaderContainer } from 'components/organisms/AnnouncementEventContainer';
import { BRAND_NAME } from 'constants/Enums';
import { CART_URL, PRODUCT_LIST, QUICK_ORDER } from 'constants/Paths';
import { useAuth } from 'context/Auth';
import { useCart } from 'context/Cart/CartContext';
import GuideContext from 'context/Guide';
import useFirebase from 'hooks/useFirebase';
import useHandleTrial from 'hooks/useHandleTrial';
import useModal from 'hooks/useModal';
import useTimeout from 'hooks/useTimeout';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { NOTIFY_IN_TOP_BAR, RECAPTCHA_SITE_KEY } from 'sysconfig';
import CookiesParser from 'utils/CookieParser';
import gtag from 'utils/gtag';
import routeHandler from 'utils/routeHandler';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import FooterWithCart from '../FooterMobile/components/FooterWithCart';
import TopSearchMobile from '../TopSearchMobile';
import styles from './styles.module.css';

const DELAY_REQUEST_MODAL = 5000;

const Footer = dynamic(() => import('components-v2/organisms/Footer'), { ssr: false });
const CustomModal = dynamic(() => import('components/mocules/CustomModal'), { ssr: false });
const NewRegisterGuestModal = dynamic(() => import('components/organisms/NewRegisterGuestModal'), { ssr: false });
const NewSignInModal = dynamic(() => import('components/organisms/NewSignInModal'), { ssr: false });
const NewSignUpModal = dynamic(() => import('components/organisms/NewSignUpModal'), { ssr: false });
const NewChangePasswordModal = dynamic(() => import('components/organisms/NewChangePasswordModal'), { ssr: false });
const NewForgetPasswordModal = dynamic(() => import('components/organisms/NewForgetPasswordModal'), { ssr: false });
const FooterMobile = dynamic(() => import('../FooterMobile'), { ssr: false });
const FooterMobileNew = dynamic(() => import('components-v2/layout/Footer/mobile'), { ssr: false });

// modal
const RequestModal = dynamic(() => import('components/organisms/RequestModal'), { ssr: false });
const PopupFullScreen = dynamic(() => import('components-v2/PopupFullScreen'), { ssr: false });

const MobileProdPage = [PRODUCT_LIST, '/manufacturers/[slug]', '/tag/[slug]'];

const defaultMV2Options = {
  offHeader: false,
  title: '',
  CustomRightHeader: false, // => truyền vào components -> nếu có CustomRightHeader thì iconRightHeader sẽ ko hiển thị
  offIconRight: false,
  iconRightHeader: {
    // các icon right header muốn hiện (có sắp xếp theo thứ tự)
    chat: true,
    notify: true,
    cart: true,
    userinfo: true,
    toQuickOrder: false,
    home: false,
  },
};

// this template is using for PC. mobile v1 and mobile
export default function Template({
  title = '',
  children,
  isMobile,
  pageName,
  pageTitle = '',
  product = '',
  showTopSearchMV2 = false,
  overrideMV2Options = {},
  isWelcomeBack = false,
}) {
  const {
    isAuthenticated,
    toggleLogin,
    isShowLogin,
    handleChangeForget,
    isShowForgetPassword,
    toggleForgetPassword,
    toggleChangePassword,
    isShowChangePassword,
    isShowSignUp,
    toggleSignUp,
    handleChangeSignIn,
    handleChangeSignUp,
    registerGuest,
    isShowRegisterGuest,
    toggleRegisterGuest,
    toggleShowGuestExpiredTime,
    isShowGuestExpiredTime,
    isShowForgotPassMess,
    toggleShowForgotPassMess,
    toggleShowBlockAccountMess,
    isShowNotFoundAccountMess,
    toggleShowNotFoundAccountMess,
    getValShowResponseNotFoundAccountMess,
    customerInfo,
    user,
  } = useAuth();

  const [openRequestModal, toggleRequestModal] = useModal();
  const toggleBeta = useMobileV2((state) => state.toggleBeta);
  const canUseMobileV2 = useStore((state) => state.canUseMobileV2);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const beta = useMobileV2((state) => state.beta);
  const [isShowWelcomeBack, toggleShowWelcomeBack] = useModal(isWelcomeBack);
  const { clearCart, isShowModalMax200Item, toggleShowModalMax200Item } = useCart();
  const [checkSupportNotification] = useFirebase(user);
  const { push, pathname, asPath, ...restRouter } = useRouter();

  useHandleTrial(user, isMobile, toggleBeta, isMobileV2, canUseMobileV2);

  const openModal = () => {
    const IS_SHOW_REQUEST_MODAL = 'isShowRequestModal';
    const isShowRequestModal = CookiesParser.CookiesParser(document.cookie)[IS_SHOW_REQUEST_MODAL];
    // chỉ hiện popup nếu đang ở / hoặc /home , vì các trang /products hiển thị popup thì bot google sẽ lấy content sai
    if (!isAuthenticated && !isShowRequestModal && !isShowLogin && !isShowSignUp && ['/', '/home'].includes(pathname)) {
      toggleRequestModal();
      document.cookie = `${IS_SHOW_REQUEST_MODAL}=true;`;
    }
  };

  useTimeout(openModal, DELAY_REQUEST_MODAL);

  const { status = '' } = customerInfo || {};

  let mv2Options = {};
  if (beta || pathname === '/conversations') {
    const isBottomNavigation = routeHandler.isBottomNavigation({ pathname, asPath, ...restRouter });
    if (!overrideMV2Options.iconRightHeader && !isBottomNavigation) {
      mv2Options = {
        ...mv2Options,
        iconRightHeader: { chat: true, cart: true, userinfo: true },
      };
    } else {
      mv2Options = {
        ...defaultMV2Options,
        ...overrideMV2Options,
      };
    }
    mv2Options = {
      ...mv2Options,
      title: overrideMV2Options?.title ? overrideMV2Options?.title : pageTitle,
    };
  }

  useEffect(() => {
    if (!isAuthenticated && isShowGuestExpiredTime) {
      clearCart();
    }
  }, [isShowGuestExpiredTime]);

  let notifyWidth = 0;
  if (NOTIFY_IN_TOP_BAR) {
    notifyWidth = isMobile ? 129 : 0;
  }

  const checkRenderUIFooter = () => {
    if (beta) {
      // show footer cart for quick order and cart page
      if (pathname === QUICK_ORDER || pathname === CART_URL) {
        return (
          <AppBar position="fixed" className={styles.appBar}>
            <FooterWithCart />
          </AppBar>
        );
      }

      if (routeHandler.isBottomNavigation({ pathname, asPath, ...restRouter })) {
        return (
          <div style={{ marginTop: 'calc(75px + 24px)' }}>
            <FooterMobileNew isAuthenticated={isAuthenticated} toggleSignUp={toggleSignUp} toggleLogin={toggleLogin} />
          </div>
        );
      }
      return <></>;
    }

    return (
      <>
        {pageName !== 'conversations' && pageName !== 'lucky-wheel' && (
          <div style={{ marginTop: '120px' }}>
            <FooterMobile product={product} />
          </div>
        )}
      </>
    );
  };

  const marginTopForHeader = () => {
    if (isMobile && beta && MobileProdPage.includes(pathname)) return '48px';
    if (isMobile && !mv2Options.offHeader) {
      if (user?.isCashback) {
        return '5px';
      }
      return '24px';
    }

    return '0px';
  };

  useEffect(() => {
    if (user) {
      checkSupportNotification();
    }
  }, [user]);

  return (
    <GuideContext isMobile={isMobile}>
      <HeadPage title={title} />

      <div id="main" className={clsx(beta && styles.new_bg_mobile, isMobile && styles.bg_mobile)}>
        <Header />
        {/* {!isMobile && <Navbar pageName={pageName} point={point} balance={balance} />} */}
        {/* Event Bottom Header */}
        {isMobile && <EventHeaderContainer marginTop={marginTopForHeader()} user={user} />}
        {/* Placeholder for NOTIFY_IN_TOP_BAR */}
        {pageName !== 'conversations' && pageName !== 'lucky-wheel' && (
          <AlertSection
            beta={beta}
            pathname={pathname}
            isMobile={isMobile}
            notifyWidth={notifyWidth}
            marginTopForHeader={() => '0px'}
            isAuthenticated={isAuthenticated}
            status={status}
            asPath={asPath}
            isMobileProdPage={beta && isMobile && MobileProdPage.includes(pathname)}
            isMobileV2={isMobileV2}
            isInvalidLicense={user?.isInvalidLicense || false}
          />
        )}
        {isMobile &&
          pageName !== 'conversations' &&
          pageName !== 'lucky-wheel' &&
          (user?.isNewLayout && pageName === 'PRD_DETAIL' ? (
            <div className={styles.text_padding_TopSearchMobile} />
          ) : (
            <TopSearchMobile isNewMobile={beta} showTopSearchMV2={showTopSearchMV2} />
          ))}
        {children}
        {isMobile ? <>{checkRenderUIFooter()}</> : <Footer />}
        {isMobile && beta && !isAuthenticated ? (
          <PopupFullScreen user={user} />
        ) : (
          !isAuthenticated && (
            <>
              <NewSignInModal visible={isShowLogin} onClose={toggleLogin} onChangeForget={handleChangeForget} onChangeSignUp={handleChangeSignUp} />
              <GoogleReCaptchaProvider language="en" reCaptchaKey={RECAPTCHA_SITE_KEY}>
                <NewForgetPasswordModal visible={isShowForgetPassword} onClose={toggleForgetPassword} />
              </GoogleReCaptchaProvider>
              <NewSignUpModal visible={isShowSignUp} onClose={toggleSignUp} onChangeSignIn={handleChangeSignIn} className="signup-modal" />
              <NewRegisterGuestModal visible={isShowRegisterGuest} onClose={toggleRegisterGuest} onChangeRegisterGuest={registerGuest} />
              <CustomModal
                visible={isShowGuestExpiredTime}
                onClose={toggleShowGuestExpiredTime}
                content="Thời gian dùng thử đã hết. Mời bạn vui lòng tạo tài khoản để sử dụng hoặc nhắn tin chúng tôi để được hỗ trợ tốt nhất!"
                btnOkRender={false}
                btnCloseRender={false}
                customBtnRender={
                  <div className={styles.btngroup}>
                    {/* <ButtonHeader
                className={styles.custombtn}
                variant="contained"
                color="primary"
                href="tel:18002038"
              >
                Gọi nhân viên hỗ trợ
              </ButtonHeader> */}
                    <ButtonHeader variant="contained" color="primary" onClick={toggleSignUp}>
                      Tạo tài khoản
                    </ButtonHeader>
                  </div>
                }
              />
              {isShowForgotPassMess && (
                <CustomModal
                  visible={isShowForgotPassMess}
                  onClose={toggleShowForgotPassMess}
                  content={`Tài khoản hoặc mật khẩu không đúng. Hãy thử lại hoặc liên hệ ${BRAND_NAME} để được hỗ trợ, xin cảm ơn!`}
                  btnOkRender={false}
                  btnCloseRender={false}
                  customBtnRender={
                    <div className={styles.btngroup}>
                      <ButtonHeader variant="contained" color="primary" onClick={toggleShowForgotPassMess}>
                        Tôi đã hiểu
                      </ButtonHeader>
                    </div>
                  }
                />
              )}
            </>
          )
        )}

        {isShowNotFoundAccountMess && (
          <CustomModal
            visible={isShowNotFoundAccountMess}
            onClose={toggleShowNotFoundAccountMess}
            content={`Không tìm thấy thông tin người dùng. Liên hệ ${BRAND_NAME} để được hỗ trợ, xin cảm ơn! ${JSON.stringify(
              getValShowResponseNotFoundAccountMess(),
            )}`}
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" color="primary" onClick={toggleShowBlockAccountMess}>
                  Tôi đã hiểu
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isShowModalMax200Item && (
          <CustomModal
            visible={isShowModalMax200Item}
            onClose={toggleShowModalMax200Item}
            content="Giỏ hàng chỉ chứa tối đa 200 mặt hàng."
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" color="primary" onClick={toggleShowModalMax200Item}>
                  Tôi đã hiểu
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isWelcomeBack && (
          <CustomModal
            visible={isShowWelcomeBack}
            onClose={toggleShowWelcomeBack}
            content={
              <>
                <Typography className={clsx(styles.text_modal_welcomeBack, isMobile && styles.text_modal_welcomeBackMobile)}>
                  Chào mừng Quý Khách quay trở lại
                </Typography>
                <Typography className={clsx(styles.text_model_info, isMobile && styles.text_model_infoMobile)}>
                  Tặng Quý Khách <span>gói ưu đãi 100K</span>, lên đơn ngay chỉ trong tháng này!
                </Typography>
                <Typography className={styles.text_model_note}>
                  Lưu ý: theo chính sách của thuocsi.vn, tài khoản sẽ bị khóa nếu bạn không đặt đơn hàng mới trong vòng 3 tháng tiếp theo!
                </Typography>
              </>
            }
            isWelcomeBack={isWelcomeBack}
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div>
                <ButtonHeader
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    push({ pathname: '/quick-order' });
                    toggleShowWelcomeBack();
                    if (isMobile) {
                      gtag.clickBtnChurnPopupMobileWeb();
                    } else {
                      gtag.clickBtnChurnPopupWeb();
                    }
                  }}
                >
                  Mua ngay
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isAuthenticated && isShowChangePassword && <NewChangePasswordModal visible={isShowChangePassword} onClose={toggleChangePassword} />}
      </div>

      {/* mặc định nếu chưa đăng nhập thì mobile dùng fb  */}
      {/* TODO: FbMessenger  FEATURE-CHAT */}
      {/* {!isAuthenticated && isMobile && isShowDelay && FACEBOOK_MESSENGER_ID && <Facebook />} off su dung Chatthuocsi thay chat fb */}
      {/* nếu không phải mobile => web => check setting  */}
      {/* {!isMobile && false && <>{MapChatComponent[chatSetting]}</>} */}
      {!beta && openRequestModal && <RequestModal visible={openRequestModal} onClose={toggleRequestModal} />}
    </GuideContext>
  );
}
