import { spinPromotion1 } from 'assets/images/promotion';
import { IGuideline } from './types';

export const GUIDE_FEATURE = {
  COLLECTION_VOUCHER_GUIDE: 'COLLECTION_VOUCHER_GUIDE',
  NOTI_RESET_POINT: 'NOTI_RESET_POINT',
  PROMO_CODES_GUIDE_SUB_MENU: 'PROMO_CODES_GUIDE_SUB_MENU',
  PROMO_CODES_GUIDE_VOUCHER_ME: 'PROMO_CODES_GUIDE_VOUCHER_ME',
  PROMO_CODES_GUIDE_VOUCHER_PRODUCT: 'PROMO_CODES_GUIDE_VOUCHER_PRODUCT',
  PROMO_CODES_GUIDE_VOUCHER_SELLER: 'PROMO_CODES_GUIDE_VOUCHER_SELLER',
  LUCKY_WHEEL: 'LUCKY_WHEEL',
  DAILY_CHECKIN: 'DAILY_CHECKIN',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  SUBSTITUTE_SKU_LIST: 'SUBSTITUTE_SKU_LIST',
  SUBSTITUTE_SKU_OTHERS: 'SUBSTITUTE_SKU_OTHERS',
} as const;

export const allGuideLine: IGuideline[] = [
  //   {
  //     page: ['/', '/home'],
  //     localStorageKey: 'home_guide_line',
  //     total: 3,
  //     data: [
  //       {
  //         id: 'step_1',
  //         index: 1,
  //         content: 'Hình ảnh sản phẩm, nút mua hàng được thay đổi vị trí để quý khách hàng có trải nghiệm mua sắm thuận tiện hơn!',
  //         canBack: false
  //       },
  //       {
  //         id: 'step_2',
  //         index: 2,
  //         content: 'Tham gia ngay để có cơ hội trúng hàng ngàn mã giảm giá, điểm tích lũy và quà tặng có giá trị khác!',
  //         canBack: true
  //       },
  //       {
  //         id: 'step_3',
  //         index: 3,
  //         content: 'Bạn có thể nhận được lượt quay miễn phí sau một khoảng thời gian nhất định.',
  //         canBack: true
  //       },
  //     ],
  //   },
  {
    page: ['/khuyenmai'],
    localStorageKey: 'promotion_guide_line',
    feature: GUIDE_FEATURE.LUCKY_WHEEL,
    isMobile: false,

    data: [
      {
        id: 'spin_1',
        image: spinPromotion1,
        category: 'sheet',
        content: (
          <span>
            Một minigame hoàn toàn mới trên <span style={{ color: '#15A959' }}>thuocsi.vn</span> <br />
            Tham gia ngay để có cơ hội trúng hàng ngàn mã giảm giá, điểm tích lũy và quà tặng có giá trị khác. <br />
            Nhấn <span style={{ color: '#E88438' }}>bắt đầu</span> để tìm hiểu ngay!
          </span>
        ),
        delay: 4000,
        canBack: false,
        isArrow: true,
        isScroll: true,
      },
      {
        id: 'spin_2',
        index: 1,
        category: 'spotlight',
        content: (
          <span>
            Để tiến hành quay thưởng, bạn có thể nhấn vào nút <b style={{ color: '#000' }}>QUAY</b>, số lượt còn lại sẽ được hiển thị ngay bên dưới
            vòng quay.
            <br />
            Khi hết lượt, nút sẽ đổi thành <b style={{ color: '#000' }}>THÊM LƯỢT</b> bạn có thể bấm vào để tìm kiếm thêm lượt.
          </span>
        ),
        canBack: false,
        isArrow: true,
        isScroll: true,
      },
      // {
      //   id: 'spin_3',
      //   index: 2,
      //   content: <span>
      //     Bạn có thể nhận được lượt quay miễn phí sau một khoảng thời gian nhất định. Tùy vào chương trình, khoảng thời gian này có thể thay đổi.
      //   </span>,
      //   canBack: true
      // },
      {
        id: 'spin_4',
        index: 2,
        subAction: 'misson',
        category: 'spotlight',
        offsetVertical: 125,
        content: (
          <span>
            <b style={{ color: '#000' }}>Nhiệm vụ</b> là nơi bạn có thể nhận được lượt quay. Hoàn thành nhiệm vụ và bạn sẽ nhận được những phần thưởng
            tương ứng.
          </span>
        ),
        canBack: true,
        isScroll: true,
      },
      {
        id: 'spin_4',
        index: 3,
        subAction: 'history',
        category: 'spotlight',
        offsetVertical: 125,
        content: (
          <span>
            Bạn có thể xem lại các phần thưởng đã nhận được từ vòng quay may mắn với <b style={{ color: '#000' }}>Lịch sử.</b>
          </span>
        ),
        canBack: true,
        isScroll: false,
      },
      {
        id: 'spin_4',
        index: 4,
        subAction: 'guideline',
        category: 'spotlight',
        offsetVertical: 125,
        content: (
          <span>
            Để biết thêm thông tin chi tiết cũng như thể lệ, quy định trả thưởng của chương trình, vui lòng bấm vào mục{' '}
            <b style={{ color: '#000' }}>Hướng dẫn.</b>
          </span>
        ),
        canBack: true,
        isArrow: true,
        isScroll: false,
      },
    ],
  },
  {
    page: ['/khuyenmai'],
    localStorageKey: 'promotion_guide_line',
    feature: GUIDE_FEATURE.LUCKY_WHEEL,
    isMobile: true,

    data: [
      {
        id: 'mobile_spin_1',
        image: spinPromotion1,
        content: (
          <div>
            <p>
              Một minigame hoàn toàn mới trên <span style={{ color: '#15A959' }}>thuocsi.vn</span>
            </p>
            <p>Tham gia ngay để có cơ hội trúng hàng ngàn mã giảm giá, điểm tích lũy và quà tặng có giá trị khác.</p>
            <p>
              Ấn <span style={{ color: '#E88438' }}>Bắt đầu</span> để tìm hiểu ngay!
            </p>
          </div>
        ),
        category: 'sheet',
        isArrow: true,
        canKnown: true,
        isScroll: true,
      },
      {
        id: 'mobile_spin_2',
        index: 1,
        content: (
          <p>
            Để tiến hành quay thưởng, bạn có thể nhấn vào nút <b style={{ color: '#000' }}>QUAY</b>, số lượt còn lại sẽ được hiển thị ngay bên dưới
            vòng quay.
            <br />
            Khi hết lượt, nút sẽ đổi thành <b style={{ color: '#000' }}>THÊM LƯỢT</b> bạn có thể bấm vào để tìm kiếm thêm lượt.
          </p>
        ),
        category: 'spotlight',
        isArrow: true,
        style: {
          borderRadius: '100%',
        },
        isScroll: true,
      },
      // {
      //   id: 'mobile_spin_3',
      //   index: 1,
      //   content:
      //     'Bạn có thể nhận được lượt quay miễn phí sau một khoảng thời gian nhất định. Tùy vào chương trình, khoảng thời gian này có thể thay đổi.',
      //   floaterProps: {
      //     disableAnimation: true,
      //   },
      //   disableBeacon: true,
      //   styles: {
      //     spotlight: {
      //       borderRadius: 12,
      //       padding: 0,
      //     },
      //   },
      //   placement: 'top',
      // },
      {
        id: 'mobile_spin_4',
        index: 2,
        content: (
          <p>
            <span style={{ color: 'black', fontWeight: 600 }}>Nhiệm vụ</span> là nơi bạn có thể nhận được lượt quay. Hoàn thành nhiệm vụ và bạn sẽ
            nhận được những phần thưởng tương ứng.
          </p>
        ),
        placement: 'top',
        category: 'spotlight',
        isArrow: false,
        canBack: true,
        style: {
          borderColor: 'white',
          borderWidth: 1,
        },
        isScroll: true,
      },
      {
        id: 'mobile_spin_5',
        index: 3,
        content: (
          <p>
            Bạn có thể xem lại các phần thưởng đã nhận được từ vòng quay may mắn với <span style={{ color: 'black', fontWeight: 600 }}>Lịch sử.</span>
          </p>
        ),
        placement: 'top',
        category: 'spotlight',
        isArrow: false,
        canBack: true,
        style: {
          borderColor: 'white',
          borderWidth: 1,
        },
        isScroll: false,
      },
      {
        id: 'mobile_spin_6',
        index: 4,
        content: (
          <p>
            Để biết thêm thông tin chi tiết cũng như thể lệ, quy định trả thưởng của chương trình, vui lòng bấm vào mục{' '}
            <span style={{ color: 'black', fontWeight: 600 }}>Hướng dẫn.</span>
          </p>
        ),
        placement: 'top',
        subAction: 'guideline',
        category: 'spotlight',
        isArrow: false,
        canBack: true,
        style: {
          borderColor: 'white',
          borderWidth: 1,
        },
        isScroll: false,
      },
    ],
  },
  {
    page: ['/lucky-wheel?source=mobile_app'],
    localStorageKey: 'promotion_guide_line_app',
    feature: GUIDE_FEATURE.LUCKY_WHEEL,
    ignoreRecaculate: true,
    isMobile: true,

    data: [
      {
        id: 'mobile_spin_1',
        image: spinPromotion1,
        content: (
          <div>
            <p>
              Một minigame hoàn toàn mới trên <span style={{ color: '#15A959' }}>thuocsi.vn</span>
            </p>
            <p>Tham gia ngay để có cơ hội trúng hàng ngàn mã giảm giá, điểm tích lũy và quà tặng có giá trị khác.</p>
            <p>
              Ấn <span style={{ color: '#E88438' }}>Bắt đầu</span> để tìm hiểu ngay!
            </p>
          </div>
        ),
        category: 'sheet',
        isArrow: true,
        canKnown: true,
        isScroll: false,
      },
      {
        id: 'mobile_spin_2',
        index: 1,
        content: (
          <p>
            Để tiến hành quay thưởng, bạn có thể nhấn vào nút <b style={{ color: '#000' }}>QUAY</b>, số lượt còn lại sẽ được hiển thị ngay bên dưới
            vòng quay.
            <br />
            Khi hết lượt, nút sẽ đổi thành <b style={{ color: '#000' }}>THÊM LƯỢT</b> bạn có thể bấm vào để tìm kiếm thêm lượt.
          </p>
        ),
        category: 'spotlight',
        isArrow: true,
        style: {
          borderRadius: '100%',
        },
        isScroll: false,
      },
      // {
      //   id: 'mobile_spin_3',
      //   index: 1,
      //   content:
      //     'Bạn có thể nhận được lượt quay miễn phí sau một khoảng thời gian nhất định. Tùy vào chương trình, khoảng thời gian này có thể thay đổi.',
      //   floaterProps: {
      //     disableAnimation: true,
      //   },
      //   disableBeacon: true,
      //   styles: {
      //     spotlight: {
      //       borderRadius: 12,
      //       padding: 0,
      //     },
      //   },
      //   placement: 'top',
      // },
      {
        id: 'mobile_spin_4',
        index: 2,
        content: (
          <p>
            <span style={{ color: 'black', fontWeight: 600 }}>Nhiệm vụ</span> là nơi bạn có thể nhận được lượt quay. Hoàn thành nhiệm vụ và bạn sẽ
            nhận được những phần thưởng tương ứng.
          </p>
        ),
        placement: 'top',
        category: 'spotlight',
        isArrow: false,
        canBack: true,
        style: {
          borderColor: 'white',
          borderWidth: 1,
        },
        isScroll: false,
      },
      {
        id: 'mobile_spin_5',
        index: 3,
        content: (
          <p>
            Bạn có thể xem lại các phần thưởng đã nhận được từ vòng quay may mắn với <span style={{ color: 'black', fontWeight: 600 }}>Lịch sử.</span>
          </p>
        ),
        placement: 'top',
        category: 'spotlight',
        isArrow: false,
        canBack: true,
        style: {
          borderColor: 'white',
          borderWidth: 1,
        },
        isScroll: false,
      },
      {
        id: 'mobile_spin_6',
        index: 4,
        content: (
          <p>
            Để biết thêm thông tin chi tiết cũng như thể lệ, quy định trả thưởng của chương trình, vui lòng bấm vào mục{' '}
            <span style={{ color: 'black', fontWeight: 600 }}>Hướng dẫn.</span>
          </p>
        ),
        placement: 'top',
        subAction: 'guideline',
        category: 'spotlight',
        isArrow: false,
        canBack: true,
        style: {
          borderColor: 'white',
          borderWidth: 1,
        },
        isScroll: false,
      },
    ],
  },
];

