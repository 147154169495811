import clsx from 'clsx';
import { LegacyRef } from 'react';
import styles from './styles.module.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  overlayRef?: LegacyRef<HTMLDivElement>;
  style?: React.CSSProperties;
};
function Overlay({ className, children, overlayRef, style }: Props) {
  return (
    <div style={style} ref={overlayRef} className={clsx(styles.overlay, className)}>
      {children}
    </div>
  );
}

export default Overlay;
