import { IStepCategory } from 'constants/data/guideline/types';
import { StaticImageData } from 'next/image';
import { containerEvents } from './TourGuidePopover';

export const useGuide = () => {
  return {
    push: (
      id: string,
      content: string,
      currentId: string,
      currentStep: number,
      totalSteps: number,
      onNext: (current: string, subAction?: string) => void,
      onBack: (current: string, subAction?: string) => void,
      subAction?: string,
      image?: StaticImageData,
      onClose?: () => void,
      index?: number,
      canBack?: boolean,
      isMobile?: boolean,
      category?: IStepCategory,
      isArrow?: boolean,
      canKnown?: boolean,
      style?: React.CSSProperties,
      offsetVertical?: number,
      delay?: number,
      ignoreRecaculate?: boolean,
      placement?: 'top' | 'left' | 'right' | 'bottom',
      isScroll?: boolean
    ) => {
      containerEvents.emit(
        'push',
        id,
        content,
        currentId,
        currentStep,
        totalSteps,
        onNext,
        onBack,
        subAction,
        image,
        onClose,
        index,
        canBack,
        isMobile,
        category,
        isArrow,
        canKnown,
        style,
        offsetVertical,
        delay,
        ignoreRecaculate,
        placement,
        isScroll
      );
    },
    open: () => {
      containerEvents.emit('open');
    },
    close: () => {
      containerEvents.emit('close');
    },
  };
};
