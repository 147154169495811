/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/**
 *
 * @param {*} noInsertScript
 */

import { ZALO_APP_ID } from 'sysconfig';

export function init() {
  const zaloShareBtn = document.getElementById('zaloShareBtn');

  if (zaloShareBtn) {
    zaloShareBtn.setAttribute('data-oaid', ZALO_APP_ID);
  }

  (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = `https://sp.zalo.me/plugins/sdk.js`;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'zalo-jssdk');
}

/**
 *
 */
export function cleanup() {
  (function (d, id) {
    const target = d.getElementById(id);
    if (target) {
      target.parentNode.removeChild(target);
    }
  })(document, 'zalo-jssdk');

  delete window.ZALO;
}
