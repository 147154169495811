import clsx from 'clsx';
import OverlayV2 from 'components-v2/atoms/OverlayV2';
import LinkComp from 'components/atoms/LinkComp';
import Image from 'next/image';
import React from 'react';
import gtag from 'utils/gtag';
import styles from './styles.module.css';

interface Props {
  items: {
    iconUrl: string;
    iconWebUrl: string;
    isActive: boolean;
    label: string;
    url: string;
  }[];
  children: React.ReactNode;
}

const SubMenu = React.memo(({ items, children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasSentEvent, setHasSentEvent] = React.useState(false);
  const handleCloseOverLay = () => {
    setIsOpen(false);
    setHasSentEvent(false);
  };

  const handleOpenOverLay = () => {
    setIsOpen(true);
    if (!hasSentEvent) {
      items.forEach((item) => {
        gtag.showSubMenu(item);
      });
      setHasSentEvent(true);
    }
  };

  const renderItems = () => {
    return items.map((item, index) => (
      <LinkComp
        key={item.label + item.url}
        removeStyles
        className={styles.MenuItems}
        name={item.label}
        href={item.url}
        onMouseOver={undefined}
        target={undefined}
        onClick={() => {
          gtag.clickSubMenubar(item);
          handleCloseOverLay();
        }}
      >
        {item?.iconUrl && <Image width="20px" height="20px" src={item?.iconUrl} alt="icon-submenu" />}
      </LinkComp>
    ));
  };

  return (
    <div
      className={clsx(styles.MenuTrigger, { isActiveMenu: isOpen })}
      onMouseOver={handleOpenOverLay}
      onMouseLeave={handleCloseOverLay}
      onFocus={() => null}
    >
      {children}
      <div className={styles.Menu} onMouseLeave={handleCloseOverLay}>
        <div className={styles.SubMenu}>
          <div className={styles?.wrapperSubmenu}>{renderItems()}</div>
        </div>
      </div>
      <OverlayV2 isOpen={isOpen} level={1} />
    </div>
  );
});

export default SubMenu;
