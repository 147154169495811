import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import { postGamificationScore, postShareLog } from 'clients/PromoClient';
import { ENUM_CONDITION_TYPE, ENUM_POPUP_SHARING_ITEM } from 'components-v2/mocules/Spinner/interface';
import { useAuth } from 'context/Auth';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { ENV, FACEBOOK_APP_ID } from 'sysconfig';
import { cleanup, initFBSharing } from './fb';

const styleContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  backgroundColor: '#4b67b7',
  padding: '4px 6px',
  color: '#fff',
  fontFamily: 'ggsm',
  borderRadius: '4px',
  maxWidth: 'max-content',
};

const styleWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-between',
  position: 'relative',
};

const styleFacebookIframe = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
};

// const domainTs = 'https://thuocsi.vn';
const currentTime = new Date().getTime();

export const checkNextSymbol = (link) => {
  const regex = /[?]\w/g;
  if (regex.test(link)) {
    return '&';
  }
  return '?';
};

export default function FacebookShareButton({
  luckyWheelCode = '',
  isSharePopup = false,
  gamificationDetailID,
  redirectLink = '',
  setOpenConfirmSharing,
  isMobile = false,
  reRenderMission,
}) {
  const postInfo = {
    method: 'feed',
    name: 'Sharing post',
    link: redirectLink || domainTs,
  };

  const router = useRouter();
  const { pathname } = router;
  const { user } = useAuth();
  const { accountID, customerID } = user || {};

  const getEnvDomain = () => {
    if (ENV === 'prd' || ENV === 'uat') {
      return 'https://thuocsi.vn';
    }
    return 'https://web.v2-stg.thuocsi.vn';
  };

  const domainTs = getEnvDomain() + '/lucky-wheel';

  const completedMission = async () => {
    await postGamificationScore({
      type: ENUM_CONDITION_TYPE.SHARE,
      customerID,
      accountID,
      action: 'share facebook',
      luckyWheelCode,
      gamificationDetailID,
      socialNetwork: ENUM_POPUP_SHARING_ITEM.FACEBOOK,
    });
    await postShareLog({
      customerID,
      accountID,
      source: isMobile ? 'thuocsi-mobile' : 'thuocsi-web',
      page: getEnvDomain() + pathname,
      screen: pathname.replace('/', ''),
      shareUrl: redirectLink || domainTs,
      platform: ENUM_POPUP_SHARING_ITEM.FACEBOOK,
    });
  };

  useEffect(() => {
    initFBSharing();

    return () => {
      cleanup();
    };
  }, []);

  if (!user) {
    return <></>;
  }

  if (isSharePopup) {
    return (
      <Grid item xs={3} md={3} style={styleWrapper}>
        <div
          className="fb-share-button lucky-wheel-fb-share-btn"
          style={styleFacebookIframe}
          data-href={redirectLink || domainTs}
          data-layout="button"
          data-size="large"
        >
          <a
            target="_blank"
            href={`http://www.facebook.com/sharer.php?&u=${encodeURIComponent(redirectLink || domainTs)}`}
            onClick={completedMission}
            className="fb-xfbml-parse-ignore"
          >
            .
          </a>
        </div>
        {/* <div id="fb-root" /> */}

        <Typography variant="body2">Facebook</Typography>
      </Grid>
    );
  }

  return (
    <div>
      <div
        id="fb-button-share"
        style={styleContainer}
        onClick={() => {
          FBCheckLogin({ postInfo, user, luckyWheelCode, gamificationDetailID, setOpenConfirmSharing, reRenderMission });
        }}
      >
        <FacebookIcon /> Chia sẻ
      </div>

      <Script src={`https://connect.facebook.net/en_GB/all.js#xfbml=1&appId=${FACEBOOK_APP_ID}`} strategy="lazyOnload" />
    </div>
  );
}
