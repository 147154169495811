import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { ENUM_NOTIFICATION_TAB, NotificationProps } from "components-v2/mocules/NewNotification/interface";
import LinkComp from "components/atoms/LinkComp";
import LoadingScreen from "components/organisms/LoadingScreen";
import { useNotify } from "context/Notifications";
import { useEffect, useLayoutEffect } from "react";
import NotificationChild from "../Child";
import styles from './styles.module.css';

const StyledTabs = styled((tabsProps) => (
  <TabList
    {...tabsProps}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderBottom: "1px solid #e9e9e9",
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 120,
    width: '100%',
    backgroundColor: '#8bd7b4',
  },
  '& .MuiTab-root': {
    minWidth: 'unset',
    width: 'calc(100%/3)'
  }

});

const StyledTab = styled((tabsProps) => <Tab disableRipple {...tabsProps} />)(
  () => ({

    textTransform: 'none',
    '&.Mui-selected': {
      color: '#2d9662',
    },
    '&.Mui-select ': {
      backgroundColor: "#edfef6",
      color: "#60b48e",
      padding: "0 4px"
    },
  }),
);

interface NotifyProps {
  date: string,
  noti: NotificationProps[]
}
interface Props {
  notiTab: ENUM_NOTIFICATION_TAB;
  handleSwitchTab: (tab: ENUM_NOTIFICATION_TAB) => void;
}

export default function NotificationTabs({ notiTab, handleSwitchTab }: Props) {
  const { notification, numberNotifyWithTab, isLoadingNotify } = useNotify();
  useEffect(() => {
    handleSwitchTab?.(ENUM_NOTIFICATION_TAB.IMPORTANT)
  }, [])

  // update height for popup notify, always make height fit with data
  useLayoutEffect(() => {
    document
      .querySelectorAll('#newNotifyMenu ul .tabPanel').forEach((link, _) => {
        (link as HTMLElement).style.removeProperty("height")
      })
    document
      .querySelectorAll('#newNotifyMenu [class*="MuiPaper-root"]').forEach((link, _) => {
        (
          (link as HTMLElement)?.style.removeProperty("height")
        )
      })
    const getHeight = document.querySelector("#newNotifyMenu ul")?.clientHeight
    if (getHeight) {
      const calcHeight = getHeight - 100
      document
        .querySelectorAll('#newNotifyMenu ul .tabPanel').forEach((link, _) => {
          (link as HTMLElement).style.setProperty("height", `${(calcHeight)}px`)
        })
      document
        .querySelectorAll('#newNotifyMenu [class*="MuiPaper-root"]').forEach((link, _) => {
          (
            (link as HTMLElement)?.style.setProperty("height", `${(getHeight)}px`)
          )
        })
    }

    return () => {
      document
        .querySelectorAll('#newNotifyMenu ul .tabPanel').forEach((link, index) => {
          (link as HTMLElement).style.removeProperty("height")
        })
      document
        .querySelectorAll('#newNotifyMenu [class*="MuiPaper-root"]').forEach((link, _) => {
          (
            (link as HTMLElement)?.style.removeProperty("height")
          )
        })
    }
  }, [notiTab, isLoadingNotify])

  return (
    <>
      <Box id="notificationTab_container">
        <TabContext value={notiTab}>
          <StyledTabs
            onChange={(_: unknown, value: ENUM_NOTIFICATION_TAB) => handleSwitchTab(value)}
            className={styles.notification_container}
          >
            <StyledTab
              className={styles.labelTab}
              value={ENUM_NOTIFICATION_TAB.IMPORTANT}
              label={(<Box display="flex" gridGap={4}>Quan trọng {numberNotifyWithTab?.important?.unread > 0 ? <span className={styles.numNoti}>{numberNotifyWithTab?.important?.unread || 0}</span> : null} </Box>)}
            />
            <StyledTab className={styles.labelTab} value={ENUM_NOTIFICATION_TAB.PRODUCT} label={(<Box display="flex" gridGap={4}>Sản phẩm {numberNotifyWithTab?.product?.unread > 0 ? <span className={styles.numNoti}>{numberNotifyWithTab?.product?.unread || 0}</span> : null } </Box>)} />
            <StyledTab className={styles.labelTab} value={ENUM_NOTIFICATION_TAB.PROMOTION} label={(<Box display="flex" gridGap={4}>Khuyến mãi {numberNotifyWithTab?.promotion?.unread > 0 ? <span className={styles.numNoti}>{numberNotifyWithTab?.promotion?.unread || 0}</span> : null } </Box>)} />
          </StyledTabs>
          <TabPanel value={ENUM_NOTIFICATION_TAB.IMPORTANT} className={clsx(styles.tabPanel_container, notification.important?.length > 0 && styles.tabPanel_containerWithData, "tabPanel")}>
            {
              isLoadingNotify ? (
                <Box className={styles.loadingContainer}>
                  <LoadingScreen />
                </Box>
              ) : (
                <Box className={clsx(styles.notifyWrap, styles.notification_list, styles.notification_tabContainer)}>
                  {
                    notification.important?.length > 0 ? notification.important?.map((item: NotifyProps) => (
                      <NotificationChild notification={item} />
                    )) : (
                      <EmptyNotification />
                    )
                  }
                </Box>
              )
            }
          </TabPanel>
          <TabPanel value={ENUM_NOTIFICATION_TAB.PRODUCT} className={clsx(styles.tabPanel_container, notification.product?.length > 0 && styles.tabPanel_containerWithData, "tabPanel")}>
            {
              isLoadingNotify ? (
                <Box className={styles.loadingContainer}>
                  <LoadingScreen />
                </Box>
              ) : (
                <Box className={clsx(styles.notifyWrap, styles.notification_list, styles.notification_tabContainer)}>
                  {
                    notification.product?.length > 0 ? notification.product?.map((item: NotifyProps) => (
                      <NotificationChild notification={item} />
                    )) : (
                      <EmptyNotification />
                    )
                  }
                </Box>
              )
            }
          </TabPanel>
          <TabPanel value={ENUM_NOTIFICATION_TAB.PROMOTION} className={clsx(styles.tabPanel_container, notification.promotion?.length > 0 && styles.tabPanel_containerWithData, "tabPanel")}>
            {
              isLoadingNotify ? (
                <Box className={styles.loadingContainer}>
                  <LoadingScreen />
                </Box>
              ) : (
                <Box className={clsx(styles.notifyWrap, styles.notification_list, styles.notification_tabContainer)}>
                  {
                    notification.promotion?.length > 0 ? notification.promotion?.map((item: NotifyProps) => (
                      <NotificationChild notification={item} />
                    )) : (
                      <EmptyNotification />
                    )
                  }
                </Box>
              )
            }
          </TabPanel>
          {
            !isLoadingNotify && notification?.[notiTab.toLowerCase()]?.length > 0 && (
              <Box className={styles.seeMore_container}>
                <LinkComp className={styles.viewAll} href={`/notifications?tab=${notiTab}`} name={undefined} onMouseOver={undefined} target={undefined} onClick={undefined}>
                  <Typography display="inline" className={styles.viewAllTitle}>
                    Xem tất cả
                  </Typography>
                </LinkComp>
              </Box>
            )
          }
        </TabContext>
      </Box>
    </>
  )
}

export const EmptyNotification = () => {
  const message = "Hiện tại bạn không có thông báo mới."
  return (
    <Box className={styles.emptyNotification}>
      <Typography>{message}</Typography>
    </Box>
  )
}
