/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IStepCategory } from 'constants/data/guideline/types';
import React, { RefObject, useEffect } from 'react';
import { checkIOS } from 'utils/deviceUtils';
import styles from './styles.module.css';

type Props = {
  category: IStepCategory;
  style?: React.CSSProperties;
  hole: { left: number; width: number; top: number; height: number };
  holeRef: RefObject<HTMLDivElement>;
  overlayRef: RefObject<HTMLDivElement>;
};

const Hole = ({ category, style, hole, holeRef, overlayRef }: Props) => {
  if (category !== 'spotlight') return <></>;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!overlayRef.current) return;
    const isIOS = checkIOS();

    if (!isIOS) {
      return;
    }
    overlayRef!.current!.style.mixBlendMode = null as any;
    setTimeout(() => {
      overlayRef!.current!.style.mixBlendMode = 'hard-light';
    }, 10);
  }, [overlayRef.current]);
  return (
    <div
      ref={holeRef}
      id="hole"
      style={{ ...style, top: hole!.top, left: hole!.left, width: hole!.width, height: hole!.height }}
      className={styles.hole}
    />
  );
};

export default Hole;
