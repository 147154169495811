import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Link from 'next/link';
import { memo } from 'react';
import styles from './styles.module.css';

/** @type {import('react').NamedExoticComponent<import(import('react').ReactNode)>} */
const LinkComp = memo(
  ({
    className,
    name,
    children,
    href,
    onMouseOver,
    target,
    variant = 'body2',
    stopProp = false,
    removeStyles = false, // make it similar to Link in NextJs
    onClick,
    padding = '',
    color = '',
    iconRight = null,
    shallow = false,
    prefetch,
    scroll,
    ...rest
  }) => {
    if (stopProp && typeof onClick !== 'function') {
      onClick = (e) => e.stopPropagation();
    }

    return (
      <Link
        href={href}
        className={clsx(!removeStyles && styles.mobile_linkRoot)}
        onMouseOver={(e) => {
          e.stopPropagation();
          onMouseOver();
        }}
        shallow={shallow}
        prefetch={prefetch}
        scroll={scroll}
        {...rest}
      >
        <a
          onClick={onClick}
          style={{ padding, color }}
          className={clsx(!removeStyles && styles.mobile_rootBase, className)}
          target={target && '_blank'}
          href={href}
          {...rest}
        >
          {children}
          {name && <Typography variant={variant}>{name}</Typography>}
          {iconRight && <div className="icon-right">{iconRight}</div>}
        </a>
      </Link>
    );
  },
);

export default LinkComp;

