import { MOBILE } from 'constants/Device';

const TABLET = /ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i;

const isTablet = () => TABLET.test(navigator?.userAgent?.toLowerCase()) || false;
const isMobileFromCtx = (ctx) => {
  const devices = ctx.req.headers['user-agent'];
  return Boolean(devices.match(MOBILE));
};
export function checkIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export default { isTablet, TABLET, isMobileFromCtx };
