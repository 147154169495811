import React from 'react';
import clsx from 'clsx';
import Portal from '@material-ui/core/Portal';
import styles from './styles.module.css';

interface Props {
  isOpen: boolean;
  level?: 1 | 2;
}
const OverlayV2 = ({ isOpen, level = 2 }: Props) => {
  return isOpen ? (
    <Portal>
      <div className={clsx(styles.MenuOverlay, styles[`level-${level}`], { [styles.fadeIn]: isOpen, [styles.fadeOut]: !isOpen })} />
    </Portal>
  ) : null;
};

export default OverlayV2;
