import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { NotificationTagColor, NotificationTagLabel } from "components-v2/mocules/NewNotification/constant";
import { DateLabelProps, ENUM_NOTIFICATION_TAG, NotificationProps } from "components-v2/mocules/NewNotification/interface";
import LinkComp from "components/atoms/LinkComp";
import { useNotify } from "context/Notifications";
import DateTimeUtils from "utils/DateTimeUtils";
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.css';

interface TagProps {
  tags: ENUM_NOTIFICATION_TAG[];
  createdTime: string;
}

interface Props {
  notification: {
    noti: NotificationProps[],
    date: string;
  }
}

const StyledBox = styled((boxProps) => (
  <Box
    {...boxProps}
  />
))({
  maxWidth: "max-content",
  borderRadius: 4,

})

export default function NotificationChild({ notification }: Props) {

  const { markReadByCode } = useNotify()

  return (
    <>
      {
        notification.date !== "Hôm nay" && (
          <DateLabel label={notification.date} />
        )
      }
      <Box>
        {
          notification.noti?.map(child => {
            const { isRead, title, link, code, tags, createdTime } = child
            const currentLinkPromotion = "/users/user-promo-codes"
            const targetLinkPromotion = "/users/my-voucher"

            return (
              <LinkComp
                key={uuidv4()}
                onClick={() => !isRead ? markReadByCode(code) : ""}
                href={link === currentLinkPromotion ? targetLinkPromotion : link}
                className={isRead ? clsx(styles.notificationsItem, styles.read) : clsx(styles.notificationsItem, styles.unRead)}
                name={undefined}
                onMouseOver={undefined}
                target={undefined}
              >
                <Box display="flex" flexDirection="column" gridGap={8} width="100%">
                  <NotificationTag tags={tags} createdTime={createdTime} />
                  <Box className={styles.notificationChild_container} display="flex" gridGap={12} gridColumnGap={12}>
                    <Box className={styles.isRead}>
                      {
                        !isRead && (
                          <Box className={styles.notification_indicator} />
                        )
                      }
                    </Box>
                    <Box display="flex" flexDirection="column" gridGap={8}>
                      <div
                        style={{ color: "#797979", fontSize: 14, fontFamily: 'ggsr', fontWeight: 400 }}
                        dangerouslySetInnerHTML={{
                          __html: title
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </LinkComp>
            )
          })
        }
      </Box>
    </>
  )
}

export const NotificationTag = ({ tags, createdTime }: TagProps): JSX.Element => (
  <Box display="flex" gridGap={4} justifyContent="space-between" marginLeft="20px">
    <Box display="flex" gridGap={8} flexWrap="wrap">
      {
        tags !== undefined && tags?.length > 0 ? (
          tags?.map(tag => tag !== ENUM_NOTIFICATION_TAG.IMPORTANT && (
            <StyledBox bgcolor={NotificationTagColor?.[tag]?.background}>
              <Typography className={styles.notification_label} variant="body2" style={{ color: NotificationTagColor?.[tag]?.text, border: `1px solid ${NotificationTagColor?.[tag]?.border}` }}>{NotificationTagLabel[tag]}</Typography>
            </StyledBox>
          ))
        ) : (
          <StyledBox bgcolor="transparent">
            <Typography className={styles.notification_label} variant="body2" />
          </StyledBox>
        )
      }
      <small className={styles.createdAt}>
        {DateTimeUtils.getTimeAgo(createdTime)}
      </small>
    </Box>
  </Box>
)

const DateLabel = ({ label }: DateLabelProps): JSX.Element => {
  return (
    <Box className={styles.dateLabel}>
      <Typography>{label}</Typography>
    </Box>
  )
}
