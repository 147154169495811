import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { postGamificationScore, postShareLog } from 'clients/PromoClient';
import clsx from 'clsx';
import { ENUM_CONDITION_TYPE, ENUM_POPUP_SHARING_ITEM } from 'components-v2/mocules/Spinner/interface';
import { LUCKYWHEEL_SHARE_ZALO_ICON } from 'constants/Images';
import { useAuth } from 'context/Auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ENV } from 'sysconfig';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { cleanup, init } from '../zalo';
import styles from './styles.module.css';

const ZaloShareContainer = styled.div`
  ${() => `
    .zalo-share-button {
      // width: 60px!important;
      // height: 60px!important;
      // display: flex !important;
      // justify-content: center
    }
  `}
`;

const styleWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'center',
  textAlign: 'center',
  minWidth: 60,
  justifyContent: 'space-between',
};

// const domainTs = 'https://thuocsi.vn';
const currentTime = new Date().getTime();

export default function ZaloShareButton({ redirectLink = '', isSharePopup = false, luckyWheelCode = '', gamificationDetailID, isMobile = false }) {
  const { user } = useAuth();
  const { customerID, accountID } = user || {};
  const router = useRouter();
  const { pathname } = router;

  const [isAllowCheckActive, setAllowCheckActive] = useState(true);

  const allowCheckActiveRef = useRef(isAllowCheckActive);

  const getEnvDomain = () => {
    if (ENV === 'prd' || ENV === 'uat') {
      return 'https://thuocsi.vn';
    }
    return 'https://web.v2-stg.thuocsi.vn';
  };

  const domainTs = getEnvDomain() + '/lucky-wheel';

  const completedMission = async (platform = ENUM_POPUP_SHARING_ITEM.ZALO) => {
    await postGamificationScore({
      type: ENUM_CONDITION_TYPE.SHARE,
      customerID,
      accountID,
      action: platform === ENUM_POPUP_SHARING_ITEM.ZALO ? 'share zalo' : 'share facebook',
      luckyWheelCode,
      gamificationDetailID,
      socialNetwork: platform,
    });
    await postShareLog({
      customerID,
      accountID,
      source: isMobile ? 'thuocsi-mobile' : 'thuocsi-web',
      page: getEnvDomain() + pathname,
      screen: pathname.replace('/', ''),
      shareUrl: redirectLink || domainTs,
      platform,
    });
  };

  useEffect(() => {
    init(redirectLink);
    const receiveMessage = async (event) => {
      // Check open popup Sharing zalo in case logged
      if (event.origin === 'https://button-share.zalo.me') {
        if (event.data.cmd === 'zsdk_open_share') {
          setAllowCheckActive(false);
        }

        if (event.data.cmd === 'zsdk_close_share') {
          if (luckyWheelCode) {
            completedMission(ENUM_POPUP_SHARING_ITEM.ZALO);
            setAllowCheckActive(true);
          }
        }
      }
    };
    window.addEventListener('message', receiveMessage, false);

    const intervalCheckIframe = setInterval(async () => {
      const callBackPopupSharing = (platform) => {
        completedMission(platform);
        document.activeElement.blur();
      };
      const iframeFb = document.querySelector('.fb-share-button iframe');
      const iframeZalo = document.querySelector('#zaloShareBtn iframe');

      if (allowCheckActiveRef.current) {
        // listener iframe clicked on case not login
        if (document.activeElement && document.activeElement === iframeFb) {
          callBackPopupSharing(ENUM_POPUP_SHARING_ITEM.FACEBOOK);
        }

        if (document.activeElement && document.activeElement === iframeZalo) {
          callBackPopupSharing(ENUM_POPUP_SHARING_ITEM.ZALO);
        }
      }
    }, 1000);

    return () => {
      cleanup();
      window.removeEventListener('message', receiveMessage);
      clearInterval(intervalCheckIframe);
    };
  }, []);

  useEffect(() => {
    allowCheckActiveRef.current = isAllowCheckActive;
  }, [isAllowCheckActive]);

  if (!user) {
    return <></>;
  }

  if (isSharePopup) {
    return (
      <Grid item xs={3} md={3} style={styleWrapper}>
        {isMobile ? (
          <Link href={`zaloshareext://shareext?url=${encodeURIComponent(redirectLink || domainTs)}&type=8&version=1`}>
            <a
              target="_blank"
              href={`zaloshareext://shareext?url=${encodeURIComponent(redirectLink || domainTs)}&type=8&version=1`}
              rel="noreferrer"
              onClick={() => completedMission(ENUM_POPUP_SHARING_ITEM.ZALO)}
            >
              <ImageFallbackStatic src={LUCKYWHEEL_SHARE_ZALO_ICON} alt="zalo Logo" width={40} height={40} />
            </a>
          </Link>
        ) : (
          <ZaloShareContainer
            id="BtnShareContainer"
            className={clsx(styles.butonShare_container)}
            dangerouslySetInnerHTML={{
              __html: `<div
                id="zaloShareBtn"
                class="zalo-share-button"
                data-href=${redirectLink || domainTs}
                data-layout=${4}
                data-color="blue"
                data-customize=${false}
              />`,
            }}
          />
        )}

        <Typography variant="body2" style={{ color: '#000' }}>
          Zalo
        </Typography>
      </Grid>
    );
  }

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<div
          id="zaloShareBtn"
          class="zalo-share-button"
          style={styleContainer}
          data-href={redirectLink || domainTs}
          data-layout="4"
          data-color="blue"
          data-customize="false"
        />`,
        }}
      />

      <Script id="zalo-jssdk" src="https://sp.zalo.me/plugins/sdk.js" strategy="lazyOnload" />
    </div>
  );
}
