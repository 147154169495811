import { AnyFunction } from 'types';

export function throttle(func: () => void, delay: number) {
  let lastCallTime = 0;
  let timeoutId: NodeJS.Timeout | null = null;

  return function (this: any, ...args: any[]) {
    const currentTime = Date.now();

    if (currentTime - lastCallTime >= delay) {
      func.apply(this, args);
      lastCallTime = currentTime;
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
        lastCallTime = Date.now();
      }, delay - (currentTime - lastCallTime));
    }
  };
}

export function debounce(func: AnyFunction, delay: number): AnyFunction {
  let timeoutId: NodeJS.Timeout;

  return function (this: any, ...args: any[]) {
    // Thêm type annotation cho this
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
