/* eslint-disable react-hooks/rules-of-hooks */
import { RefObject, createRef } from "react";
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface ProductListStore {
    firstSubstituteSkuRef: RefObject<HTMLDivElement>;
    triggerTour: number;
    substituteIndex: number | null;
}

interface Actions {
    updateTriggerTour: (payload: number) => void;
    updateSubstituteIndex: (payload: number | null) => void;
}

type IniticalProductListState = ProductListStore
type ProductListState = IniticalProductListState & { actions: Actions}

const INITIAL_STATE: IniticalProductListState = {
    firstSubstituteSkuRef: createRef<HTMLDivElement>(),
    triggerTour: 0,
    substituteIndex: null
}

const useProductListStates = create<ProductListState>()(
    devtools((set, get) => ({
        ...INITIAL_STATE,
        actions: {
            updateTriggerTour: () => {
                const { triggerTour } = get()
                set({ triggerTour: triggerTour + 1 })
            },
            updateSubstituteIndex: (payload: number | null) => {
                set({ substituteIndex: payload })
            }
        }
    }))
)

export const userProductListActions = () => useProductListStates((state) => state.actions)

export default useProductListStates