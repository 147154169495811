const ImageHoverEffect = ({ defaultImage, activeImage, width = '20px', height = '20px', hovered = false }) => {

  // Function to escape brackets in the file name
  const escapeBracketsInFileName = (url) => {
    let fileName = url.split('/').pop();

    // Check if the file name contains brackets
    if (/\(|\)/.test(fileName)) {
      fileName = fileName.replace(/\(/g, '\\(').replace(/\)/g, '\\)');

      const modifiedUrl = url.replace(/\/([^\/]+)$/, '/' + fileName);

      return modifiedUrl;
    }

    return url;
  };

  const backgroundImage = hovered && activeImage ? `url(${escapeBracketsInFileName(activeImage)})` : `url(${escapeBracketsInFileName(defaultImage)})`;

  return (
    <div
      className="image-container"
      style={{
        position: 'relative',
        width,
        height,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage,
        transition: 'background-image 0.3s ease',
      }}
    />
  );
};

export default ImageHoverEffect;
