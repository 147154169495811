import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { HOME, HOME_PAGE } from 'constants/Paths';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import gtag from 'utils/gtag';
import styles from './styles.module.css';

function AnnouncementEventContainer({ isShowAnnouncement, isMobile = false }) {
  if (!isShowAnnouncement) return null;

  return (
    <Box
      className={clsx(
        isMobile ? styles.headerAnnouncement_content : styles.headerAnnouncement_content_desktop,
        isMobile && styles.headerAnnouncement_content_mobile,
      )}
    >
      <span>Chúc mừng khách hàng Kim Cương may mắn tham gia chương trình </span>
      <b>"TÍCH LŨY DOANH SỐ THÁNG - TRAO THƯỞNG BẤT NGỜ".</b>
      {/* <span>. Kiểm tra ngay!</span> */}
      <a
        onClick={(e) => {
          gtag.clickCashbackBar();
          window.location.href = '/users/rewards/226';
          e?.stopPropagation();
        }}
        style={{ cursor: 'pointer' }}
      >
        {' '}
        Kiểm tra ngay!
      </a>
    </Box>
  );
}

export function EventHeaderContainer({ marginTop, user }) {
  const { pathname } = useRouter();
  const isShow = [HOME, HOME_PAGE].includes(pathname) && user?.isCashback;
  if (!isShow) return <></>;

  return (
    <div
      className={styles.notifyHeader_bottom_wrapper}
      style={{
        marginTop,
        marginBottom: isShow ? '6px' : '0px',
        height: isShow ? '32px' : '0px',
      }}
    >
      <a
        onClick={(e) => {
          gtag.clickCashbackBar();
          window.location.href = '/users/rewards/226';
          e?.stopPropagation();
        }}
      >
        <AnnouncementEventContainer isShowAnnouncement={isShow} isMobile />
      </a>
    </div>
  );
}

export default memo(AnnouncementEventContainer);

