/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/**
 *
 * @param {*} noInsertScript
 */

import { postGamificationScore } from 'clients/PromoClient';
import { ENUM_CONDITION_TYPE, ENUM_POPUP_SHARING_ITEM } from 'components-v2/mocules/Spinner/interface';
import { FACEBOOK_APP_ID, FACEBOOK_MESSENGER_ID } from 'sysconfig';
import NotifyUtils from './NotifyUtils';

const ENUM_LOGIN_STATUS = {
  CONNECTED: 'connected',
  NOT_AUTHORIZED: 'not_authorized',
};

export function init() {
  const chatbox = document.getElementById('fb-customer-chat');
  chatbox.setAttribute('page_id', FACEBOOK_MESSENGER_ID); // TODO: move to args
  chatbox.setAttribute('attribution', 'biz_inbox');

  window.fbAsyncInit = function () {
    if (FB)
      FB.init({
        xfbml: true,
        version: 'v14.0',
      });
  };

  (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = `https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js`;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export function initFBSharing() {
  const buttonShare = document?.getElementById('fb-button-share');
  buttonShare?.setAttribute('app_id', FACEBOOK_APP_ID);

  // FB JS SDK config and setup
  window.fbAsyncInit = function () {
    if (FB) {
      FB.init({
        appId: FACEBOOK_APP_ID,
        status: true,
        xfbml: true,
        version: 'v3.2',
      });
    }
  };

  (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = `https://connect.facebook.net/en_GB/all.js#xfbml=1&appId=${FACEBOOK_APP_ID}`;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export function FBSharePost({ postInfo, user, luckyWheelCode, gamificationDetailID, reRenderMission }) {
  const { method, link } = postInfo;
  const { accountID, customerID } = user || {};

  async function callBackSharing(response) {
    if (response && !response.error_message) {
      if (typeof response !== 'undefined') {
        await postGamificationScore({
          type: ENUM_CONDITION_TYPE.SHARE,
          customerID,
          accountID,
          action: 'share facebook post',
          luckyWheelCode,
          gamificationDetailID,
          socialNetwork: ENUM_POPUP_SHARING_ITEM.FACEBOOK,
        });

        NotifyUtils.success('Chia sẻ bài viết facebook thành công!');
        // re-render list mission
        reRenderMission();
      }
    } else {
      NotifyUtils.error('Hệ thống chưa ghi nhận chia sẻ bài viết! Nếu chưa đăng nhập hãy đăng nhập và thử lại');
    }
  }

  if (FB) {
    FB.ui(
      {
        display: 'popup',
        method,
        link,
      },
      // callback
      async function (response) {
        callBackSharing(response);
      },
    );
  }
}

export function FBCheckLogin({ postInfo, user, luckyWheelCode, gamificationDetailID, setOpenConfirmSharing, reRenderMission }) {
  async function statusChangeCallback(response) {
    if (response.status === ENUM_LOGIN_STATUS.CONNECTED) {
      // user had logged before, show popup sharing post
      FBSharePost({ postInfo, user, luckyWheelCode, gamificationDetailID, reRenderMission });
    } else if (response.status === ENUM_LOGIN_STATUS.NOT_AUTHORIZED) {
      // not authorized, request access permission for user
      NotifyUtils.info('Vui lòng cấp quyền chia sẽ bài viết cho thuocsi.vn !');
      FB.login(
        function (response) {
          if (response.authResponse) {
            // user have allow access permission
            setOpenConfirmSharing(true);
          } else {
            // user is not logged in
            NotifyUtils.error('Đăng nhập thất bại! Vui lòng thử lại.');
          }
        },
        { scope: 'email' },
      );
    } else {
      FB.login(
        function (response) {
          if (response.authResponse) {
            // user have allow access permission
            setOpenConfirmSharing(true);
          } else {
            // user close or not allow
            NotifyUtils.error('Đăng nhập thất bại! Vui lòng thử lại.');
          }
        },
        { scope: 'email' },
      ); // which data to access from user profile
    }
  }

  FB.getLoginStatus(async function (response) {
    statusChangeCallback(response);
  }, true);
}
/**
 *
 */
export function cleanup() {
  (function (d, id) {
    const target = d.getElementById(id);
    if (target) {
      target.parentNode.removeChild(target);
    }
  })(document, 'facebook-jssdk');

  delete window.FB;
}
